import { Grid } from '@mui/material';
import React, { useState } from 'react';
import {
  BoxContentHeading,
  BoxContentItem1,
  BoxContentItem2,
  BoxContentItemChipList,
  BoxContentItemContact,
  BoxContentSummary,
  DrawerContentWorkExperience,
  ParserDetailBox,
  DrawerParserDetail,
  TwoDate,
} from 'components/cards/ParserDetail-Items';


export const ParserDetailList = ({ data, myRef, showHeading = true }) => {
  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const [clickedSection, changeClickedSection] = useState(null);

  const handlerOpenDrawer = section_name => {
    changeClickedSection(section_name);
    setOpenDrawer(true);
  };
  const {
    basic_info,
    work_experience,
    education,
    skills,
    achievements_and_honors,
    contact_info,
    job_objectives,
    education_match,
    experience_match,
    compatible_skills,
    occupation,
    languages
  } = data;
  return (
    <div className="ParserDetail-List">
      <DrawerParserDetail
        open={isOpenDrawer}
        onClose={() => setOpenDrawer(false)}
        label={`${work_experience?.total_work_experience.toFixed(0)} years`}
      >
        {clickedSection === 'work_experience' && (
          <DrawerContentWorkExperience data={work_experience && work_experience} />
        )}
      </DrawerParserDetail>

      <Grid ref={myRef} container rowSpacing={{ xs: '16px' }} columnSpacing={{ xs: '16px' }}>
        {basic_info && showHeading && (
          <Grid item xs={12} md={12}>
            <BoxContentHeading
              name={basic_info?.name}
              title={occupation ? occupation?.[0] : ''}
              social={basic_info?.links}
            />
          </Grid>
        )}

        {basic_info && !!basic_info.summary && (
          <Grid item xs={12} md={12}>
            <ParserDetailBox title="SUMMARY" label={`PRO`}>
              <BoxContentSummary text={basic_info.summary} />
            </ParserDetailBox>
          </Grid>
        )}

        {work_experience && (
          <Grid item xs={12} md={6}>
            <ParserDetailBox
              match_score={experience_match}
              title="WORK EXPERIENCE"
              label={`${work_experience?.total_work_experience.toFixed(0)} years`}
              onClick={() => handlerOpenDrawer('work_experience')}
              /* onClick={null} */
            >
              {work_experience?.works.map((item, index) => {
                return (
                  <BoxContentItem1
                    key={index}
                    text_1={item?.company || '-'}
                    text_2={item?.job_title || ''}
                    /* text_3={`${item?.start_date || ''} - ${item?.end_date || ''}`} */
                    text_3={<TwoDate start_date={item?.start_date} end_date={item?.end_date} />}
                  />
                );
              })}
            </ParserDetailBox>
          </Grid>
        )}
        {job_objectives && job_objectives.length > 0 && (
          <Grid item xs={12} md={6}>
            <ParserDetailBox title="JOB OBJECTIVES" label={job_objectives[0]['list'][0]}>
              {job_objectives.map((item, index) => {
                return <BoxContentItemChipList key={index} title={item.title} list={item.list} />;
              })}
            </ParserDetailBox>
          </Grid>
        )}

        {education && education?.length > 0 && (
          <Grid item xs={12} md={6}>
            <ParserDetailBox
              match_score={education_match}
              title="EDUCATION"
              label={education[0]['education_level']}
              gap="20"
            >
              {education.map((item, index) => {
                return <BoxContentItem2 key={index} data={item} />;
              })}
            </ParserDetailBox>
          </Grid>
        )}
        {basic_info?.skill_of_resume && basic_info?.skill_of_resume?.length > 0 && (
          <Grid item xs={12} md={6}>
            <ParserDetailBox title="SKILLS" label={`${basic_info?.skill_of_resume.length} Skills`}>
              {[
                {
                  title: 'Skills Of Resume',
                  list: basic_info?.skill_of_resume,
                  type: 'normal',
                },
                /* {
                  title: 'Hirize Enriched Skills',
                  list: hirize_enriched_skills,
                  type: 'pro',
                }, */
              ].map((item, index) => {
                return (
                  <BoxContentItemChipList
                    compatible_skills={compatible_skills}
                    key={index}
                    title={item.title}
                    list={item.list}
                    type={item.type}
                  />
                );
              })}
            </ParserDetailBox>
          </Grid>
        )}
        {skills && skills?.length > 0 && (
          <Grid item xs={12} md={6}>
            <ParserDetailBox title="ENRICHED SKILLS" label={`${skills.length} Skills`}>
              {[
                {
                  title: 'Enriched Skills',
                  list: skills,
                  type: 'normal',
                },
                /* {
                  title: 'Hirize Enriched Skills',
                  list: hirize_enriched_skills,
                  type: 'pro',
                }, */
              ].map((item, index) => {
                return (
                  <BoxContentItemChipList
                    compatible_skills={compatible_skills}
                    key={index}
                    title={item.title}
                    list={item.list}
                    type={item.type}
                  />
                );
              })}
            </ParserDetailBox>
          </Grid>
        )}
        
        {contact_info && (
          <Grid item xs={12} md={6}>
            <ParserDetailBox title="CONTACT INFO" direction="row">
              <BoxContentItemContact data={contact_info} />
            </ParserDetailBox>
          </Grid>
        )}
        


     
     



        {achievements_and_honors && achievements_and_honors?.length > 0 && (
          <Grid item xs={12} md={6}>
            <ParserDetailBox title="ACHIEVEMENTS & HONORS" label={`${achievements_and_honors.length} added`}>
              {achievements_and_honors.map((item, index) => {
                return (
                  <BoxContentItem1
                    key={index}
                    text_1={item?.name}
                    text_2={item?.institution_name}
                    text_3={item?.date}
                  />
                );
              })}
            </ParserDetailBox>
          </Grid>
        )}



        {languages && languages?.length > 0 && (
          <Grid item xs={12} md={6}>
            <ParserDetailBox
              match_score={experience_match}
              title="LANGUAGES"
            >
              {languages.map((item, index) => {
                return (
                  <BoxContentItem1
                    key={index}
                    text_1={item?.language || '-'}
                    /* text_3={`${item?.start_date || ''} - ${item?.end_date || ''}`} */
                    text_3={item?.proficiency_level || ''}
                  />
                );
              })}
            </ParserDetailBox>
          </Grid>
        )}

        {/* {contact_info && (
          <Grid item xs={12} md={6}>
            <ParserDetailBox title="CONTACT INFO" direction="row">
              <BoxContentItemContact data={contact_info} />
            </ParserDetailBox>
          </Grid>
        )} */}
      </Grid>
    </div>
  );
};
